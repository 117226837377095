@media (max-width: 640px) {
    .nav-link{
        display: none !important;
    }
    .login-container {
        width: 80%;
    }
    .main-header {
        height: 50px;
        background-color: #d2d2d2;
        display: flex;
        width: 100%;
        align-content: stretch;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }
    .dashboard-left-panel {
        width: 0px;
        overflow: hidden;
    }
    .dashboard-right-panel{
        width: -webkit-fill-available;
        /* padding: 10px; */
    }
    .mobile-menu-wrapper {
        text-align: right;
        height: 50px;
        display: flex;
        justify-content: flex-end;
        align-content: center;
        align-items: center;
        flex-wrap: nowrap;
        flex-direction: row;
    }
    .menu-side-true .dashboard-left-panel {
        position: fixed;
        width: 290px;
        z-index: 100;
        height: 100%;
        top: 0px;
    }
    .menu-side-true .dashboard-right-panel{
        width: -webkit-fill-available;
        /* padding: 10px; */
    }
    .container-btn-export-report{
        display: flex;
        gap: 5px;
        margin-top: 5px !important;
    }
    .btn-export-report {
        margin-left: 0px;
        margin-right: 0px;
        text-align: center;
        font-size: 10px;
    }
}